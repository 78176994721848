<app-nav></app-nav>

<main class="page page-status-enquiry" *ngIf="update_success">
  <aside class="header-image-half"></aside>

  <div class="container content-section">
    <h1 class="content-section-title text-right mt-0 text-success text-uppercase" translate="update_1">
      Successfully updated
    </h1>

    <hr />

    <div class="row mt-5">
      <div class="col-lg-12 text-center column text-uppercase">
        <h1 class="text-dark">
          <i class="fa fa-check-circle text-success"></i>&nbsp;
          <span translate="update_2">Your application is successfully updated</span>
        </h1>
        <h3 class="text-secondary mt-2" translate="update_3">
          Please wait around 1-3 working days
        </h3>
        <hr />
        <button class="btn btn-primary btn-sm" (click)="goHome()">
          <i class="fa fa-home"></i>
          <span translate>Home</span>
        </button>
      </div>
    </div>
  </div>
</main>

<main class="page page-apply-review" *ngIf="
    (person.application_id !== '' && update_success == false) ||
    (person.application_id == '' && update_success == false)
  ">
  <div class="container content-section mt-lg-5">
    <form action="#" class="apply-form">
      <div class="row mt-5">
        <div class="col">
          <fieldset>
            <legend class="section-header-legend bg-blue-primary text-white" translate>
              Review
            </legend>
            <div class="form-control-wrapper">
              <div class="row mb-5">
                <div class="col-lg-6 d-flex flex-column align-items-center">
                  <h5 class="text-primary font-weight-bold mb-2 text-uppercase" translate>
                    Photo
                  </h5>
                  <!-- <div class="image-placeholder photo"></div> -->
                  <img id="photo" [src]="
                      setFormLocalStorage.photoImgUrl ||
                      './assets/images/placeholder-photo.jpg'
                    " class="mb-3 image-placeholder photo" alt="" />
                </div>
                <div class="col-lg-6 d-flex flex-column align-items-center">
                  <h5 class="text-primary font-weight-bold mt-5 mt-lg-0 mb-2 text-uppercase" translate>
                    Passport
                  </h5>
                  <!-- <div class="image-placeholder passport"></div> -->
                  <img id="passport" [src]="
                      setFormLocalStorage.passportImgUrl ||
                      './assets/images/placeholder-passport.jpg'
                    " alt="your image" class="image-placeholder passport" alt="" />
                </div>
                <!-- <div class="col-lg-4 d-flex flex-column align-items-center">
                  <h5 class="text-primary font-weight-bold mt-5 mt-lg-0 mb-2">PASSPORT COVER</h5>

                  <img id="passport" [src]="custImage.imgCover || './assets/images/placeholder-passport-cover.jpg'"
                    alt="your image" class="image-placeholder photo" alt="">
                </div> -->
              </div>

              <hr />

              <div class="row pt-4">
                <div class="col-lg-6">
                  <h5 class="text-primary font-weight-bold mb-2 text-uppercase" translate>
                    Personal Information
                  </h5>
                  <ul class="pl-0">
                    <li class="row pl-0">
                      <div class="col colon-after-label" translate="sn">
                        SURNAME
                      </div>
                      <div class="col" id="lastName">{{ person.lastName }}</div>
                    </li>
                    <li class="row pl-0">
                      <div class="col colon-after-label" translate="given_name">
                        GIVEN NAMES
                      </div>
                      <div class="col" id="firstName">
                        {{ person.firstName }}
                      </div>
                    </li>
                    <li class="row pl-0">
                      <div class="col colon-after-label" translate="gender">
                        GENDER
                      </div>
                      <div class="col" id="selectedGender">
                        {{ person.gender }}
                      </div>
                    </li>
                    <li class="row pl-0">
                      <div class="col colon-after-label" translate="dob">
                        DATE OF BIRTH
                      </div>
                      <div class="col">{{ dob }}</div>
                    </li>
                    <li class="row pl-0">
                      <div class="col colon-after-label" translate="cob">
                        COUNTRY OF BIRTH
                      </div>
                      <div class="col" id="selectedCountryBirth">
                        {{ person.selectedCountryBirth }}
                      </div>
                    </li>
                    <li class="row pl-0">
                      <div class="col colon-after-label" translate="tel">
                        TELEPHONE
                      </div>
                      <div class="col" id="tel">{{ person.tel }}</div>
                    </li>
                    <li class="row pl-0">
                      <div class="col colon-after-label" translate="occ">
                        OCCUPATION
                      </div>
                      <div class="col" id="occupation">
                        {{ person.occupation }}
                      </div>
                    </li>
                    <li class="row pl-0">
                      <div class="col colon-after-label" translate="res">
                        RESIDENTIAL ADDRESS
                      </div>
                      <div class="col" id="resAddress">
                        {{ person.resAddress }}
                      </div>
                    </li>
                    <li class="row pl-0">
                      <div class="col colon-after-label" translate="stp">
                        STATE / PROVINCE
                      </div>
                      <div class="col" id="province">{{ person.province }}</div>
                    </li>
                    <li class="row pl-0">
                      <div class="col colon-after-label" translate="coc">
                        COUNTRY OF CITIZENSHIP
                      </div>
                      <div class="col" id="selectedCountryCity">
                        {{ person.selectedCountryCity }}
                      </div>
                    </li>
                  </ul>
                  <br />
                  <h5 class="text-primary font-weight-bold mt-5 mt-lg-0 mb-2" translate>
                    PASSPORT DETAILS
                  </h5>
                  <ul class="pl-0">
                    <li class="row pl-0">
                      <div class="col colon-after-label" translate="ppn">
                        PASSPORT NO.
                      </div>
                      <div class="col" id="passportNumber">
                        {{ person.passportNumber }}
                      </div>
                    </li>
                    <li class="row pl-0">
                      <div class="col colon-after-label" translate="doi">
                        DATE OF ISSUE
                      </div>
                      <div class="col">{{ issue_date }}</div>
                    </li>
                    <li class="row pl-0">
                      <div class="col colon-after-label" translate="epd">
                        EXPIRY DATE
                      </div>
                      <div class="col">{{ expired_date }}</div>
                    </li>
                    <li class="row pl-0">
                      <div class="col colon-after-label" translate="cop">
                        COUNTRY OF PASSPORT
                      </div>
                      <div class="col" id="selectedCountryOfPassport">
                        {{ person.selectedCountryOfPassport }}
                      </div>
                    </li>
                  </ul>
                </div>

                <div class="col-lg-6">
                  <h5 class="text-primary font-weight-bold mb-2" translate>
                    TRAVEL DETAILS
                  </h5>
                  <ul class="pl-0">
                    <!-- <li class="row pl-0">
                      <div class="col colon-after-label" translate="vst">
                        VISA TYPE
                      </div>
                      <div class="col" id="selectedvisaType">
                        {{ person.selectedvisaType }}
                      </div>
                    </li>
                    <li class="row pl-0">
                      <div class="col colon-after-label" translate="poe">
                        PORT OF ENTRY
                      </div>
                      <div class="col" id="selectedportOfEntry">
                        {{ person.selectedportOfEntry }}
                      </div>
                    </li> -->
                    <li class="row pl-0">
                      <div class="col colon-after-label" translate="ide">
                        INTENDED DATE OF ENTRY
                      </div>
                      <div class="col">{{ intended_date }}</div>
                    </li>
                    <li class="row pl-0">
                      <div class="col colon-after-label" translate="vh">
                        VEHICLE
                      </div>
                      <div class="col" id="vehicle">{{ person.vehicle }}</div>
                    </li>
                    <li class="row pl-0">
                      <div class="col colon-after-label" translate="pot">
                        PURPOSE OF TRIP
                      </div>
                      <div class="col" id="selectedPurpose">
                        {{ person.selectedPurpose }}
                      </div>
                    </li>
                    <li class="row pl-0">
                      <div class="col colon-after-label" translate="adv">
                        ADDRESS DURING VISIT
                      </div>
                      <div class="col" id="addressDuringVisit">
                        {{ person.addressDuringVisit }}
                      </div>
                    </li>
                    <li class="row pl-0">
                      <div class="col colon-after-label" translate="nd-review">
                        NEXT DESTINATION
                      </div>
                      <div class="col" id="next_destination">
                        {{ person.next_destination }}
                      </div>
                    </li>
                  </ul>

                  <br />

                  <div *ngIf="person.is_request_doc == true">
                    <h5 class="text-primary font-weight-bold mb-2">
                      REQUEST INFORMATION
                    </h5>
                    <ul class="pl-0">
                      <li class="row pl-0">
                        <div class="col colon-after-label">
                          Hotel reservation
                        </div>
                        <div class="col" id="hotel_doc">
                          {{ person.hotel_doc }}
                        </div>
                      </li>
                      <li class="row pl-0">
                        <div class="col colon-after-label">
                          Flight reservation
                        </div>
                        <div class="col" id="flight_doc">
                          {{ person.flight_doc }}
                        </div>
                      </li>
                      <li class="row pl-0">
                        <div class="col colon-after-label">
                          Third country visa
                        </div>
                        <div class="col" id="third_visa_doc">
                          {{ person.third_visa_doc }}
                        </div>
                      </li>
                      <li class="row pl-0">
                        <div class="col colon-after-label">
                          Guarantor documen
                        </div>
                        <div class="col" id="guarantor_doc">
                          {{ person.guarantor_doc }}
                        </div>
                      </li>
                      <li class="row pl-0">
                        <div class="col colon-after-label">Bank Statement</div>
                        <div class="col" id="bank_statement_doc">
                          {{ person.bank_statement_doc }}
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
        </div>
      </div>

      <footer class="row text-center mt-5">
        <div class="col d-flex flex-column flex-lg-row justify-content-center">
          <button (click)="goBack($event)" id="btnBack" class="btn btn-default btn-icon d-inline-flex mr-lg-3">
            <svg id="btnBack" aria-hidden="true" class="svg-inline--fa fa-arrow-alt-left fa-w-14"
              data-icon="arrow-alt-left" data-prefix="fal" focusable="false" role="img" viewBox="0 0 448 512"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M395.4 159.9H256V75c0-35.5-43-53.5-68.3-28.3L15.4 218.8c-20.6 20.6-20.6 53.8 0 74.4l172.3 172.1c25.1 25.1 68.3 7.4 68.3-28.3v-85h139.4c29 0 52.6-23.6 52.6-52.6v-86.9c0-29-23.6-52.6-52.6-52.6zM416 299.4c0 11.4-9.2 20.6-20.6 20.6H224v117c0 7.1-8.6 10.7-13.7 5.7L38 270.6c-8-8-8-21.1 0-29.1L210.3 69.4c5-5 13.7-1.5 13.7 5.7v117h171.4c11.4 0 20.6 9.2 20.6 20.6z"
                fill="currentColor"></path>
            </svg>
            <span class="ml-2" id="btnBack">Back</span>
          </button>
          <button id="submitApplicationBtn" class="btn btn-primary btn-icon d-inline-flex mt-3 mt-lg-0 ml-lg-3"
            [disabled]="person.firstName == '' || loading" (click)="submitApplicationForm($event)">
            <span class="mr-2" id="submitApplicationBtn">Continue</span>
            <svg id="submitApplicationBtn" aria-hidden="true" class="svg-inline--fa fa-arrow-alt-right fa-w-14"
              data-icon="arrow-alt-right" data-prefix="fal" focusable="false" role="img" viewBox="0 0 448 512"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M32 212.57A20.57 20.57 0 0 1 52.57 192H224V75a8 8 0 0 1 13.66-5.66L410 241.44a20.56 20.56 0 0 1 0 29.11L237.66 442.63A8 8 0 0 1 224 437V320H52.57A20.57 20.57 0 0 1 32 299.42v-86.85m-32 0v86.85A52.63 52.63 0 0 0 52.57 352H192v85c0 35.51 43 53.5 68.27 28.3l172.3-172.08a52.55 52.55 0 0 0 0-74.4L260.27 46.71C235.15 21.62 192 39.36 192 75v85H52.57A52.63 52.63 0 0 0 0 212.57z"
                fill="currentColor"></path>
            </svg>
          </button>
        </div>
      </footer>
    </form>
  </div>
</main>
<app-footer></app-footer>