import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import * as $ from "jquery";
import { CustomerImage, Person } from "src/app/models/formData";
import { FormDataService } from "src/app/services/form-data.service";
import { RestService } from "src/app/services/rest.service";
import { environment } from "src/environments/environment";
// import * as moment from 'moment';

import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatRadioChange } from "@angular/material";
import {
  NgbDateParserFormatter,
  NgbDatepickerConfig,
  NgbModal,
} from "@ng-bootstrap/ng-bootstrap";
import { LOCAL_STORAGE, WINDOW } from "@ng-toolkit/universal";
import * as moment from "moment-timezone";
import { NgxImageCompressService } from "ngx-image-compress";
import { NgxSpinnerService } from "ngx-spinner";
import { AppComponent } from "src/app/app.component";
import { CountCartService } from "src/app/services/count-cart.service";
import { NgbDateCustomParserFormatter } from "../../functions/ngb-date-fr-parser-formatter";
import { ImageCropperComponent } from "../image-cropper/image-cropper.component";
import { ImageCroppedEvent } from "../image-cropper/interfaces";
import * as dayjs from "dayjs";

@Component({
  selector: "app-edit-application",
  templateUrl: "./edit-application.component.html",
  styleUrls: ["./edit-application.component.css"],
  // providers: [{provide: NgbDateAdapter, useClass: NgbDateNativeAdapter}]
  providers: [
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter },
  ],
})
export class EditApplicationComponent implements OnInit, AfterViewInit {
  NIB3 = environment.featureNIB3;
  mDataArrayCountry: any;
  mDataArrayVisaType: any[];
  mDataArrayPortEntry: any[];
  mDataArrayCallingCode: any[];
  mPurpose: any[];

  passportSrc: string;
  person: Person;
  custImage: CustomerImage;
  mrzData: any;
  expDate: any[] = [];
  dateBirth: any[] = [];
  form: any;

  imageChangedEvent: any = "";
  imageChangedEventPass: any = "";
  imageChangedEventPassCover: any = "";
  croppedImage: any = "";
  croppedImagePass: any = "";
  croppedImagePassCover: any = "";
  cropperReady = false;
  cropperReadyPass = false;
  countryBirthSelected: Number;

  issueDate: { year: number; month: number; day: number };
  expiredDate: { year: 0; month: 0; day: 0 };
  intendedDate: { year: number; month: number; day: number };

  myExpiredDate: { year: number; month: number; day: number };

  // Validate intended
  intentedMessage: string;
  isIntendedError = false;
  isPhotoBigSize = false;
  isPassportBigSize = false;
  isCoverPassportBigSize = false;
  acceptTerm = false;
  isExpiredDate = false;
  selectedPhoneCode = "USA +1";
  selectedCountry;
  @Input() formDatas: FormData;
  isRequestCountry = false;
  selectedFile: File;
  selectedFileFlight: File;
  selectedFileThirdVisa: File;
  selectedFileGuarantor: File;
  hotel_doc_big_size = false;
  flight_doc_big_size = false;
  third_visa_doc_big_size = false;
  guarantor_visa_doc_big_size = false;

  hotel_warning_message = "";
  flight_warning_message = "";
  third_visa_warning_message = "";
  guarantor_warning_message = "";

  personForm: FormGroup;
  submitted = false;

  photo_count = 0;
  passport_count = 0;
  passport_cover_count: number;
  isPhotoSmallSize: boolean;
  isPassportSmallSize: boolean;
  isPassportCoverSmallSize = false;
  find_status;
  mDataArrayCountryBirth: any;
  bank_statement_doc_big_size: boolean;
  selectedFileBankStatement: any;
  @ViewChild("content", { static: true }) private content;
  days = 0;
  except_message = "";
  next_2month = { year: 0, month: 0, day: 0 };
  next_7day = { year: 0, month: 0, day: 0 };
  today = { year: 0, month: 0, day: 0 };
  isPassportCoverBigSize: boolean;
  cropperReadyPassCover: boolean;
  citizens = "";

  raceSection: boolean = false;
  proofFile: any;
  proofFileName: string = "";
  proofFilePreview: any;
  proofFileCount: number = 0;
  personRace: number;

  @ViewChild(ImageCropperComponent, { static: true })
  imageCropper: ImageCropperComponent;
  @ViewChild("imagePhoto", { static: true })
  photoCropper: ImageCropperComponent;
  @ViewChild("imagePassport", { static: true })
  passportCropper: ImageCropperComponent;

  imgResultBeforeCompression: string = "";
  imgResultAfterCompression: string = "";

  setFormLocalStorage = {
    photoBase64: "",
    passportBase64: "",
    photoImg: "",
    passportImg: "",
    photoImgUrl: "",
    passportImgUrl: "",
    lastName: "",
    firstName: "",
    gender: "",
    dateBirth: { year: 0, month: 0, day: 0 },
    genderSelect: "",
    countryBirth: null,
    telephone: "",
    occupation: "",
    residentialAddress: "",
    stateProvince: "",
    countryCity: null,
    passportNum: "",
    dateIssue: { year: 0, month: 0, day: 0 },
    dateExpire: { year: 0, month: 0, day: 0 },
    countryPassport: null,
    intendDate: "",
    vehicle: "",
    purposeTrip: null,
    addressDuringVisit: "",
    nextDestination: "",
    checkExpDate: dayjs().format("YYYY-MM-DD"),
  };

  constructor(
    @Inject(WINDOW) private window: Window,
    @Inject(LOCAL_STORAGE) private localStorage: any,
    public app: AppComponent,
    private router: Router,
    private rest: RestService,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private formDataService: FormDataService,
    private formBuilder: FormBuilder,
    private _messageService: CountCartService,
    private modalService: NgbModal,
    config: NgbDatepickerConfig,
    private spinner: NgxSpinnerService,
    private imageCompressor: NgxImageCompressService,
    private el: ElementRef
  ) {
    config.minDate = { year: 1900, month: 1, day: 1 };
  }
  width: number;
  height: number;

  onRaceChange(event: MatRadioChange) {
    this.person.race = Number(event.value);
    if (event.value == 0) {
      this.personRace = 0;
      this.raceSection = false;
      this.person.race = 0;
      this.person.family_side = "";
      this.person.family_member_names = "";
      this.person.family_relation = "";
      this.enableNext = true;
    } else {
      this.personRace = 304;
      this.raceSection = true;
      this.person.race = 304;
      this.enableNext = false;
    }
  }

  proofFileChangeEvent(e: any): void {
    this.proofFileCount = 1;
    const file = e.target.files[0];
    this.proofFileName = file.name;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.proofFile = reader.result.toString();
      this.proofFilePreview = reader.result.toString();
      this.person.proof_file_preview = reader.result.toString();
    };
  }

  removeProofFile() {
    this.proofFile = "";
    this.proofFilePreview = "";
    this.proofFileName = "";
    this.proofFileCount = 0;
  }

  enableNext: boolean = false;
  async onUploadProofFile() {
    const formData = new FormData();
    formData.append("prooffile", this.proofFile);
    if (this.proofFile) {
      const result = await this.rest.uploadProofFileService(formData);
      if (result.status == "success") {
        this.proofFile = "";
        this.proofFilePreview = "";
        this.proofFileName = "";
        this.proofFileCount = 0;
        this.enableNext = true;
        this.person.proof_of_laotian = result.name;
        this.personForm.value.proof_of_laotian = result.name;
      }
    }
  }

  fileChangeEvent(event: any): void {
    //   const file = event.target.files[0];
    //   const img = new Image();
    //   img.src = window.URL.createObjectURL(file);
    //   const width = img.naturalWidth;
    //   const height = img.naturalHeight;
    //   if ( width < 300 && height < 300 ) {
    //     this.isPhotoSmallSize = true;
    //     return;
    //  }
    if (event.target.files[0].size > 10485760) {
      this.isPhotoBigSize = true;
      return;
    } else {
      this.isPhotoBigSize = false;
      this.imageChangedEvent = event;
      this.photo_count = 1;
    }
  }
  fileChangeEventPass(event: any): void {
    //   const file = event.target.files[0];
    //   const img = new Image();
    //   img.src = window.URL.createObjectURL(file);
    //   const width = img.naturalWidth;
    //   const height = img.naturalHeight;
    //   if ( width < 300 && height < 300 ) {
    //     this.isPassportSmallSize = true;
    //     return;
    //  }
    if (event.target.files[0].size > 10485760) {
      this.isPhotoBigSize = true;
      this.isPassportBigSize = true;
      return;
    } else {
      this.isPassportBigSize = false;
      this.imageChangedEventPass = event;
      this.passport_count = 1;
    }
  }
  fileChangeEventPassCover(event: any): void {
    if (event.target.files[0].size > 2097152) {
      this.isPassportCoverBigSize = true;
      return;
    } else {
      this.isPassportCoverBigSize = false;
      this.imageChangedEventPassCover = event;
      this.passport_cover_count = 1;
    }
  }
  cropperReadyLog() {
    console.log("Cropper ready");
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    // this.person.imgPhoto = event.base64;
    // console.log(event);
  }
  imageCroppedPass(event: ImageCroppedEvent) {
    this.croppedImagePass = event.base64;
    // this.person.imgPass = event.base64;
    // console.log(event);
  }
  imageCroppedPassCover(event: ImageCroppedEvent) {
    this.croppedImagePassCover = event.base64;
  }
  imageLoaded() {
    this.cropperReady = true;
  }
  imageLoadedPass() {
    this.cropperReadyPass = true;
  }
  imageLoadedPassCover() {
    this.cropperReadyPassCover = true;
  }
  loadImageFailed() {
    // console.log('Load failed');
  }
  loadImageFailedPass() {
    // console.log('Load failed');
  }
  loadImageFailedPassCover() {
    // console.log('Load failed');
  }

  rotateLeft(img: "photo" | "passport") {
    if (img == "photo") {
      this.photoCropper.rotateLeft();
    } else if (img == "passport") {
      this.passportCropper.rotateLeft();
    } else {
      this.imageCropper.rotateLeft();
    }
  }

  rotateRight(img: "photo" | "passport") {
    if (img == "photo") {
      this.photoCropper.rotateRight();
    } else if (img == "passport") {
      this.passportCropper.rotateRight();
    } else {
      this.imageCropper.rotateRight();
    }
  }

  flipHorizontal(img: "photo" | "passport") {
    if (img == "photo") {
      this.photoCropper.flipHorizontal();
    } else if (img == "passport") {
      this.passportCropper.flipHorizontal();
    } else {
      this.imageCropper.flipHorizontal();
    }
  }

  flipVertical(img: "photo" | "passport") {
    if (img == "photo") {
      this.photoCropper.flipVertical();
    } else if (img == "passport") {
      this.passportCropper.flipVertical();
    } else {
      this.imageCropper.flipVertical();
    }
  }

  verifyToken(credential) {
    this.rest.verifyToken(credential).subscribe(
      (result) => {
        if (
          !result["auth"] &&
          result.message === "Failed to authenticate token."
        ) {
          this.router.navigate(["/apply"], { replaceUrl: true });
          localStorage.removeItem(environment.localAuthenInfo);
        }
        if (!result["auth"] && result.message === "TokenExpired") {
          this.router.navigate(["/apply"], { replaceUrl: true });
          localStorage.removeItem(environment.localAuthenInfo);
        }
        if (!result["responseStatus"]) {
          localStorage.removeItem(environment.localAuthenInfo);
          this.router.navigate(["/apply"], { replaceUrl: true });
        } else {
          localStorage.setItem(
            environment.localAuthenInfo,
            JSON.stringify(result)
          );
          this.router.navigate(["/application"]);
        }
      },
      (error) => {
        console.error(error);
        localStorage.removeItem(environment.localAuthenInfo);
        this.router.navigate(["/apply"], { replaceUrl: true });
      }
    );
  }

  getCountryList() {
    this.rest.getCountry().subscribe((result) => {
      // this.mDataArrayCountry = result.country;
      this.mDataArrayCountry = result;
    });
  }
  getCountryBirthList() {
    this.rest.getCountryBirth().subscribe((result) => {
      // this.mDataArrayCountryBirth = result.country;
      this.mDataArrayCountryBirth = result;
    });
  }

  getVisaTypeList() {
    this.rest.getVisaType().subscribe((result) => {
      this.mDataArrayVisaType = result;
    });
  }
  getPortEntryList() {
    this.rest.getPortEntry().subscribe((result) => {
      this.mDataArrayPortEntry = result;
    });
  }
  getPurposeList() {
    this.rest.getPurpose().subscribe((result) => {
      this.mPurpose = result;
    });
  }
  getCallingCodeList() {
    this.rest.getCallingCode().subscribe((result) => {
      this.mDataArrayCallingCode = result;
    });
  }
  getExceptMessage() {
    this.rest.getExceptMessage().subscribe((result) => {
      setTimeout(() => {
        this.except_message = result["except_message"];
      });
    });
  }

  getApplicationInfo(application_id) {
    this.rest.getApplicationInfo(application_id).subscribe(async (rs) => {
      if (!environment.production) console.log("Get Application Info: ", rs);
      if (rs.status !== "notfound") {
        this.find_status = true;
        this.person.application_id = rs.application_id;
        this.person.hotel_doc = rs.hotel_document;
        this.person.flight_doc = rs.flight_document;
        this.person.guarantor_doc = rs.guarantor_document;
        this.person.third_visa_doc = rs.third_visa_document;
        this.person.bank_statement_doc = rs.bank_statement;
        this.person.imgPhotoName = rs.photo;
        this.person.imgPassName = rs.passport;
        

        if (
          `${rs.photo_name}`.includes("data:image/png;base64,") ||
          `${rs.passport_name}`.includes("data:image/png;base64,")
        ) {
          this.person.imgPhoto = rs.photo_name;
          this.person.imgPass = rs.passport_name;
          this.custImage.imgPhoto = rs.photo_name;
          this.custImage.imgPass = rs.passport_name;
          this.setFormLocalStorage.photoImgUrl = `${environment.node_image_url}/photo/${rs.photo.substring(0, 8)}/${rs.photo}`;
          this.setFormLocalStorage.passportImgUrl = `${environment.node_image_url}/passport/${rs.passport.substring(0, 8)}/${rs.passport}`;
        } else {
          this.person.imgPhoto = "data:image/png;base64," + rs.photo_name;
          this.person.imgPass = "data:image/png;base64," + rs.passport_name;
          this.custImage.imgPhoto = "data:image/png;base64," + rs.photo_name;
          this.custImage.imgPass = "data:image/png;base64," + rs.passport_name;
        }
        this.person.firstName = rs.first_name;
        this.person.lastName = rs.last_name;
        this.person.gender = rs.gender;
        this.person.DOB = {
          year: Number(moment(rs.dob).tz("Asia/Vientiane").format("YYYY")),
          month: Number(moment(rs.dob).tz("Asia/Vientiane").format("MM")),
          day: Number(moment(rs.dob).tz("Asia/Vientiane").format("DD")),
        };
        this.person.countryBirth = rs.country_birth_id;
        this.person.tel = rs.telephone;
        this.person.occupation = rs.occupation;
        this.person.resAddress = rs.residential_address;
        this.person.province = rs.state_province;
        this.person.countryCity = rs.country_of_city;

        this.person.passportNumber = rs.passport_number;
        this.person.issueDate = {
          year: Number(
            moment(rs.issue_date).tz("Asia/Vientiane").format("YYYY")
          ),
          month: Number(
            moment(rs.issue_date).tz("Asia/Vientiane").format("MM")
          ),
          day: Number(moment(rs.issue_date).tz("Asia/Vientiane").format("DD")),
        };
        this.person.expiredDate = {
          year: Number(
            moment(rs.expired_date).tz("Asia/Vientiane").format("YYYY")
          ),
          month: Number(
            moment(rs.expired_date).tz("Asia/Vientiane").format("MM")
          ),
          day: Number(
            moment(rs.expired_date).tz("Asia/Vientiane").format("DD")
          ),
        };
        this.person.countryOfPassport = rs.country_of_passport;

        this.person.visaType = rs.visa_type_id;
        this.person.portOfEntry = rs.port_of_entry;
        this.person.intendedDate = {
          year: Number(
            moment(rs.intended_date).tz("Asia/Vientiane").format("YYYY")
          ),
          month: Number(
            moment(rs.intended_date).tz("Asia/Vientiane").format("MM")
          ),
          day: Number(
            moment(rs.intended_date).tz("Asia/Vientiane").format("DD")
          ),
        };
        this.next_7day.year = Number(
          moment(rs.intended_date).tz("Asia/Vientiane").format("YYYY")
        );
        this.next_7day.month = Number(
          moment(rs.intended_date).tz("Asia/Vientiane").format("MM")
        );
        this.next_7day.day = Number(
          moment(rs.intended_date).tz("Asia/Vientiane").format("DD")
        );
        this.localStorage.setItem("previousData", JSON.stringify(this.setFormLocalStorage));
        this.person.vehicle = rs.vehicle;
        this.person.purposeOfTrip = rs.purpos_of_trip;
        this.person.addressDuringVisit = rs.address_during_visit;
        this.person.next_destination = rs.next_destination;
        this.person.selectedGender = rs.gender;
        this.person.selectedCountryBirth = rs.country_birth;
        this.person.selectedCountryCity = rs.country_city;
        this.person.selectedCountryOfPassport = rs.country_passport;
        this.person.selectedvisaType = rs.visa_type_name;
        this.person.selectedportOfEntry = rs.port_of_entry_name;
        this.person.selectedPurpose = rs.purpose_name;
        this.person.first_action_date = rs.first_action_date;
        this.personRace = rs.race;
        this.person.race = rs.race;
        this.person.family_side = rs.family_side ? rs.family_side : "";
        this.person.family_relation = rs.family_relation
          ? rs.family_relation
          : "";
        this.person.family_member_names = rs.family_member_names
          ? rs.family_member_names
          : "";
        this.person.proof_of_laotian = rs.proof_of_laotian
          ? rs.proof_of_laotian
          : "";
        if (rs.proof_of_laotian) {
          const split = `${rs.proof_of_laotian}`.split("/").pop();
          if (split == null || split == "null") {
            this.proofFilePreview = null;
            this.person.proof_file_preview = "";
            this.proofFileCount = 0;
          } else {
            this.proofFileCount = 1;
          }
          this.proofFilePreview = rs.proof_of_laotian;

          this.person.proof_file_preview = rs.proof_of_laotian;
        }
        // console.log(rs.country_of_city);
        this.showRequestDocument(await rs.country_of_city);
        this.showRequestDocument(await rs.country_of_passport);
      } else {
        this.router.navigate(["/apply"]);
      }
    });
  }

  deletePerson(application_id) {
    const id = application_id[0].application_id;
    this.rest.deletePerson(id).subscribe((result) => {
      // console.log(result);
    });
  }

  readURL(event: any): void {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = (e: any) => {
        if (event.target.getAttribute("name") === "inputPassportFile") {
          this.person.imgPass = e.target.result;
        } else if (event.target.getAttribute("name") === "inputPhotoFile") {
          this.person.imgPhoto = e.target.result;
        }
      };
      reader.readAsDataURL(file);
      // reader.readAsDataURL(event.target.files[0]);
    }
  }
  save(form: any): boolean {
    if (this.person.imgPassName == null) {
      return false;
    }
    if (this.person.imgPhotoName == null) {
      return false;
    }
    if (this.person.countryCity === null) {
      return false;
    }
    if (this.person.countryOfPassport === null) {
      return false;
    }
    if (!form.valid) {
      return false;
    }
    if (this.personRace == 304) {
      return false;
    }
    console.log(this.person);
    this.formDataService.setPerson(this.person);
    return true;
  }

  goToNext(form: any) {
    if (this.save(form)) {
      // Navigate to the work page
      this.router.navigate(["/review"]);
    }
  }
  IntendedOnChange(event) {
    this.intendedDate = event;
    this.validateIntendedDate(event);
  }
  validateIntendedDate(event) {
    if (this.expiredDate && this.intendedDate) {
      const inten = new Date(
        this.intendedDate.year,
        this.intendedDate.month - 1,
        this.intendedDate.day
      );
      const ex = new Date(
        this.expiredDate.year,
        this.expiredDate.month - 1,
        this.expiredDate.day
      );

      const intended = moment(inten);
      const expired = moment(ex);
      const diff = expired.diff(intended, "months");

      // console.log('inten :' + inten);
      // console.log('ex :' + ex);
      // console.log('diff month more 6 or not:' + diff);

      if (diff >= 6) {
        this.isIntendedError = false;
      } else {
        if (this.app.SelectedLang == "en") {
          this.intentedMessage =
            "The passport should have minimum validity of 6 months from the date of arrival in Laos. Your expected date of entry should be within 60 days as of the date of an eVisa application. You are suggested to apply at least 5 days before your departure.";
        } else if (this.app.SelectedLang == "cn") {
          this.intentedMessage =
            "护照有效期不得少于6个月，将从入境老挝之日起开始计算。入境日期不得晚于申请电子签证后60天。建议您至少在出发前 5 天提出申请。";
        } else if (this.app.SelectedLang == "fr") {
          this.intentedMessage =
            "The passport should have minimum validity of 6 months from the date of arrival in Laos. Your expected date of entry should be within 60 days as of the date of an eVisa application. You are suggested to apply at least 5 days before your departure.";
        }

        this.isIntendedError = true;
        return;
      }
    }
    if (this.intendedDate) {
      const inten = new Date(
        this.intendedDate.year,
        this.intendedDate.month - 1,
        this.intendedDate.day
      );
      const intended = moment(inten);
      const now = new Date();
      const checkintenDate = intended.diff(now, "days") + 1;
      // console.log('check if intended not more 7 day ' + checkintenDate);

      if (checkintenDate < 5) {
        // this.intentedMessage = 'Intended Date of Entry must be 5 days after the day of applying.';
        // this.isIntendedError = true;
        return;
      } else {
        this.isIntendedError = false;
      }
    }
  }
  ExpiredOnChange(event) {
    this.person.expiredDate = event;
    this.expiredDate = event;
    if (this.expiredDate) {
      const curent_date = new Date();
      const ex = new Date(
        this.expiredDate.year,
        this.expiredDate.month - 1,
        this.expiredDate.day
      );
      const now = moment(curent_date);
      const expired = moment(ex);
      const diff = expired.diff(now, "days");
      if (diff <= 0) {
        if (this.expiredDate.day > 0) {
          this.isExpiredDate = true;
        }
      } else {
        this.isExpiredDate = false;
        this.validateIntendedDate(event);
      }
    }
  }

  IssueOnChange(event) {
    this.issueDate = event;
    if (this.issueDate && this.expiredDate) {
      const curent_date = new Date();
      const ex = new Date(
        this.expiredDate.year + 10,
        this.expiredDate.month - 1,
        this.expiredDate.day
      );
      const now = moment(curent_date);
      const expired = moment(ex);
      const diff = expired.diff(now, "days");
      if (diff <= 0) {
        if (this.expiredDate.day > 0) {
          this.isExpiredDate = true;
        }
      } else {
        this.isExpiredDate = false;
        this.validateIntendedDate(event);
      }
    }
  }

  onCountryBirthChange(selectedId: number) {
    const s: string = this.mDataArrayCountryBirth.find(
      (x: any) => x.country_id == selectedId
    );
    this.person.selectedCountryBirth = s["country_name"];
  }

  onPurposeChange(selectedId: number) {
    const s: string = this.mPurpose.find(
      (x: any) => x.purpose_id == selectedId
    );
    this.person.selectedPurpose = s["purpose_name"];
  }

  onGenderChange(event: Event) {
    const selectedOptions = event.target["options"];
    const selectedIndex = selectedOptions.selectedIndex;
    const selectElementText = selectedOptions[selectedIndex].text;
    this.person.selectedGender = selectElementText;
  }

  onCountryOfCityChange(selectedId: string, event) {
    console.log("Country City:", selectedId);
    if (selectedId !== "") {
      const s: string = this.mDataArrayCountry.find(
        (x: any) => x.country_id == selectedId
      );
      this.person.selectedCountryCity = s["country_name"];
      this.person.selectedCountryOfPassport = s["country_name"];
      this.citizens = s["citizens"];
      this.days = s["stay_day"];
      // this.personForm.patchValue({
      //   countryOfPassport: selectedId
      // });
      const country = this.mDataArrayCountry.find(
        (x) => x.country_id == selectedId
      );
      if (country.is_except == 1) {
        event.srcElement.blur();
        event.preventDefault();
        this.openLg(this.content);
      }
      if (country.request_attach === 1) {
        this.person.is_request_doc = true;
      } else if (country.request_attach == 0) {
        this.person.is_request_doc = false;
      }
    } else {
      this.person.is_request_doc = false;
    }
  }
  openLg(content) {
    this.modalService.open(content, { size: "lg", centered: true });
  }
  onCountryOfPassportChange(selectedId: string, event) {
    console.log("COuntry ID:", selectedId);
    if (selectedId !== "") {
      if (this.selectedCountry) {
        this.selectedCountry = selectedId;
      }
      const country = this.mDataArrayCountry.find(
        (x) => x.country_id == selectedId
      );
      this.person.selectedCountryOfPassport = country["country_name"];
      if (country.is_except === 1) {
        event.srcElement.blur();
        event.preventDefault();
        this.openLg(this.content);
      }
      if (country.request_attach === 1) {
        this.person.is_request_doc = true;
      } else if (country.request_attach == 0) {
        this.person.is_request_doc = false;
      }
    } else {
      this.person.is_request_doc = false;
    }
  }

  showRequestDocument(selectedId: string) {
    if (selectedId) {
      if (this.mDataArrayCountry.length > 0) {
        const s: string = this.mDataArrayCountry.find(
          (x: any) => x.country_id == selectedId
        );
        this.person.selectedCountryCity = s["country_name"];
        this.person.selectedCountryOfPassport = s["country_name"];
        this.personForm.patchValue({
          countryOfPassport: selectedId,
        });
        const country = this.mDataArrayCountry.find(
          (x) => x.country_id == selectedId
        );

        if (country.is_except == 1) {
        }
        if (country.request_attach === 1) {
          this.person.is_request_doc = true;
        } else if (country.request_attach == 0) {
          this.person.is_request_doc = false;
        }
      }
    } else {
      this.person.is_request_doc = false;
    }
  }

  onVisaTypeChange(selectedId: number) {
    const s: string = this.mDataArrayVisaType.find(
      (x: any) => x.visa_type_id == selectedId
    );
    this.person.selectedvisaType = s["visa_type_name"];
  }
  onPortOfEntryChange(selectedId: number) {
    const s: string = this.mDataArrayPortEntry.find(
      (x: any) => x.port_of_entry_id == selectedId
    );
    this.person.selectedportOfEntry = s["port_of_entry_name"];
  }

  onSubmit(form: any) {
    
    if (this.personForm.invalid) {
      this.personForm.markAllAsTouched();
      this.scrollToFirstInvalidControl();
    }
    // console.log(form.value);
    // console.log(this.personForm.controls['expiredDate'].valid);
    this.submitted = true;
    if (!this.person.countryCity) {
      console.log("Country city is not selected", this.person.countryCity);
      return;
    }

    if (!this.person.countryOfPassport) {
      console.log(
        "Country passport is not selected",
        this.person.countryOfPassport
      );
      return;
    }
    // console.log(this.person);
    if (this.person.is_request_doc === true) {
      if (
        this.person.hotel_doc === "" ||
        this.person.hotel_doc === null ||
        this.hotel_doc_big_size === true
      ) {
        return;
      }
      if (
        this.person.flight_doc === "" ||
        this.person.flight_doc === null ||
        this.flight_doc_big_size === true
      ) {
        return;
      }
      if (
        this.person.third_visa_doc === "" ||
        this.person.third_visa_doc === null ||
        this.third_visa_doc_big_size === true
      ) {
        return;
      }
      if (
        this.person.hotel_doc === "" ||
        this.person.hotel_doc === null ||
        this.guarantor_visa_doc_big_size === true
      ) {
        return;
      }
      if (
        this.person.bank_statement_doc === "" ||
        this.person.bank_statement_doc === null ||
        this.bank_statement_doc_big_size === true
      ) {
        return;
      }
    }

    // console.log(this.personForm);
    // console.log('form log: ' + this.personForm.valid);
    // if (this.personForm.valid) {
    if (this.save(form)) {
    this.localStorage.setItem('previousData', JSON.stringify(this.setFormLocalStorage));
      // Navigate to the work page
      this.router.navigate(["/review"]);
    }
    // }
  }
  checkAccept() {
    this.acceptTerm = !this.acceptTerm;
  }

  async onUploadPhotoImage() {
    // this.person.imgPhoto = this.croppedImage;
    this.custImage.imgPhoto = this.croppedImage;

    const formData = new FormData();
    formData.append("upload_file_photo", this.custImage.imgPhoto);
    formData.append("version", "2");

    await this.rest.uploadPhotoFile(formData).subscribe(async (result) => {
      if (Array.isArray(result)) {
        this.person.imgPhotoName = result[0].name;
        this.setFormLocalStorage.photoImgUrl = `${environment.node_image_url}/photo/${dayjs().format('YYYYMMDD')}/${result[0].name}`;
      } else {
        this.person.imgPhotoName = result.name;
        this.setFormLocalStorage.photoImgUrl = `${environment.node_image_url}/photo/${dayjs().format('YYYYMMDD')}/${result.name}`;
      }
    });
  }
  async onUploadPassportImage() {
    this.spinner.show();
    setTimeout(() => this.spinner.hide(), 3000);
    // this.person.imgPass = this.croppedImagePass;
    this.custImage.imgPass = this.croppedImagePass;
    const event = this.imageChangedEventPass;
    const formData = new FormData();
    formData.append("upload_file_passport", this.custImage.imgPass);

    await this.rest.uploadPassportFile(formData).subscribe(async (result) => {
      this.person.imgPassName = result.name;
      this.setFormLocalStorage.passportImgUrl = `${environment.node_image_url}/passport/${dayjs().format('YYYYMMDD')}/${result.name}`;
      console.log(result);
      if (result.mrz !== null) {
        if (result.mrz.status !== "failed") {
          // this.mrzData = result.mrz.data.passport;
          // this.person.lastName = this.mrzData.lastName;
          // this.person.firstName = this.mrzData.firstName;
          // this.person.passportNumber = this.mrzData.passportNumber;
          // this.person.gender = `${this.mrzData.sex}`.toLowerCase();
          // this.expDate = result.mrz.data.passport.expirationDate.split("-");
          // this.dateBirth = this.mrzData.birthDate.split("-");
          // this.person.countryCity = this.mrzData.countryOfCitizenshipId;
          // this.person.countryOfPassport = this.mrzData.countryOfPassportId;

          if (this.mrzData.lastName)
            this.person.lastName = this.mrzData.lastName;
          if (this.mrzData.firstName)
            this.person.firstName = this.mrzData.firstName;
          if (this.mrzData.passportNumber)
            this.person.passportNumber = this.mrzData.passportNumber;
          if (this.mrzData.sex)
            this.person.gender = `${this.mrzData.sex}`.toLowerCase();
          if (result.mrz.data.passport)
            this.expDate = result.mrz.data.passport.expirationDate.split("-");
          if (this.mrzData.birthDate)
            this.dateBirth = this.mrzData.birthDate.split("-");
          if (this.mrzData.countryOfCitizenshipId)
            this.person.countryCity = this.mrzData.countryOfCitizenshipId;
          if (this.mrzData.countryOfPassportId)
            this.person.countryOfPassport = this.mrzData.countryOfPassportId;

          // this.person.countryCity = null;
          // this.person.countryOfPassport = null;

          this.ExpiredOnChange({
            year: parseInt(this.expDate[0]),
            month: parseInt(this.expDate[1]),
            day: parseInt(this.expDate[2]),
          });
          // this.DateBirthOnChange({ year: parseInt(this.dateBirth[0]), month: parseInt(this.dateBirth[1]), day: parseInt(this.dateBirth[2]) })
          this.onCountryOfCityChange(
            this.mrzData.countryOfCitizenshipId || this.person.countryCity,
            ""
          );
          this.onCountryOfPassportChange(
            this.mrzData.countryOfPassportId || this.person.countryOfPassport,
            ""
          );
        }
      }
    });
  }

  onUploadPassportCoverImage() {
    // this.person.imgPass = this.croppedImagePass;
    this.custImage.imgCover = this.croppedImagePassCover;
    const event = this.imageChangedEventPassCover;
    const formData = new FormData();
    formData.append("upload_file_passport_cover", this.custImage.imgCover);
    this.rest.uploadPassportCoverFile(formData).subscribe(async (result) => {
      this.person.imgCoverName = await result.name;
    });
  }

  onFileHotelChanged(event) {
    if (event.target.files[0].size > 2097152) {
      this.hotel_doc_big_size = true;
    } else {
      this.hotel_doc_big_size = false;
      this.selectedFile = event.target.files[0];
      this.person.hotel_doc = event.target.files[0].name;
      // console.log(this.person.hotel_doc);
      this.onUpload();
    }
  }

  onFileFlightChanged(event) {
    if (event.target.files[0].size > 2097152) {
      this.flight_doc_big_size = true;
    } else {
      this.flight_doc_big_size = false;
      this.selectedFileFlight = event.target.files[0];
      this.onUploadFlight();
    }
  }

  onFileThirdVisaChanged(event) {
    if (event.target.files[0].size > 2097152) {
      this.third_visa_doc_big_size = true;
    } else {
      this.third_visa_doc_big_size = false;
      this.selectedFileThirdVisa = event.target.files[0];
      this.person.third_visa_doc = event.target.files[0].name;
      this.onUploadThirdVisa();
    }
  }

  onFileGuarantorInputUploadChanged(event) {
    if (event.target.files[0].size > 2097152) {
      this.guarantor_visa_doc_big_size = true;
    } else {
      this.guarantor_visa_doc_big_size = false;
      this.selectedFileGuarantor = event.target.files[0];
      this.person.guarantor_doc = event.target.files[0].name;
      this.onUploadGuarantor();
    }
  }
  onFileBankStatementChanged(event) {
    if (event.target.files[0].size > 2097152) {
      this.bank_statement_doc_big_size = true;
    } else {
      this.bank_statement_doc_big_size = false;
      this.selectedFileBankStatement = event.target.files[0];
      this.person.bank_statement_doc = event.target.files[0].name;
      this.onUploadBankStatement();
    }
  }

  onUpload() {
    const uploadData = new FormData();
    uploadData.append("hotel_file", this.selectedFile, this.selectedFile.name);
    this.rest.uploadHoteldoc(uploadData).subscribe((result) => {
      this.person.hotel_doc = result.name;
    });
  }
  onUploadFlight() {
    const uploadData = new FormData();
    uploadData.append(
      "flight_file",
      this.selectedFileFlight,
      this.selectedFileFlight.name
    );
    this.rest.uploadFlightdoc(uploadData).subscribe((result) => {
      this.person.flight_doc = result.name;
    });
  }
  onUploadThirdVisa() {
    const uploadData = new FormData();
    uploadData.append(
      "third_visa_file",
      this.selectedFileThirdVisa,
      this.selectedFileThirdVisa.name
    );
    this.rest.uploadThirdVisadoc(uploadData).subscribe((result) => {
      this.person.third_visa_doc = result.name;
    });
  }
  onUploadGuarantor() {
    const uploadData = new FormData();
    uploadData.append(
      "guarantor_file",
      this.selectedFileGuarantor,
      this.selectedFileGuarantor.name
    );
    this.rest.uploadGuarantordoc(uploadData).subscribe((result) => {
      this.person.guarantor_doc = result.name;
    });
  }
  onUploadBankStatement() {
    const uploadData = new FormData();
    uploadData.append(
      "bank_statement_file",
      this.selectedFileBankStatement,
      this.selectedFileBankStatement.name
    );
    this.rest.uploadBankStatementdoc(uploadData).subscribe((result) => {
      this.person.bank_statement_doc = result.name;
    });
  }
  // convenience getter for easy access to form fields
  get f(): any {
    return this.personForm.controls;
  }
  ngAfterViewInit(): void {
    const authInfo = JSON.parse(
      this.localStorage.getItem(environment.localAuthenInfo)
    );
    if (authInfo) {
      this.person.token = authInfo.token;
      this.getCountryList();
      this.getCountryBirthList();
      this.getVisaTypeList();
      this.getPortEntryList();
      this.getCallingCodeList();
      this.getPurposeList();
      this.getExceptMessage();
    } else {
      this.router.navigate(["apply"]);
    }
  }
  ngOnInit() {
    // this.next_7day.year = Number(moment().add(5, 'd').format('YYYY'));
    // this.next_7day.month = Number(moment().add(5, 'd').format('MM'));
    // this.next_7day.day = Number(moment().add(5, 'd').format('DD'));

    this.today.year = Number(moment().format("YYYY"));
    this.today.month = Number(moment().format("MM"));
    this.today.day = Number(moment().format("DD"));
    this.next_2month.year = Number(moment().add(60, "d").format("YYYY"));
    this.next_2month.month = Number(moment().add(60, "d").format("MM"));
    this.next_2month.day = Number(moment().add(60, "d").format("DD"));

    this.route.params.subscribe(async (params) => {
      this.getApplicationInfo(params["id"]);
    });

    this.personForm = this.formBuilder.group({
      firstName: [
        "",
        [Validators.required, Validators.pattern("[a-zA-Z ,.]*")],
      ],
      lastName: ["", [Validators.required, Validators.pattern("[a-zA-Z ,.]*")]],
      gender: ["", [Validators.required]],
      DOB: ["", [Validators.required]],
      countryBirth: ["", [Validators.required]],
      tel: [
        "",
        [Validators.required, Validators.pattern("^[0-9+-s]{6}[0-9+-s]+$")],
      ],
      occupation: [
        "",
        [Validators.required, Validators.pattern("[a-zA-Z0-9 ,]*")],
      ],
      resAddress: [
        "",
        [Validators.required, Validators.pattern("[a-zA-Z0-9 ,.#]*")],
      ],
      province: [
        "",
        [Validators.required, Validators.pattern("[a-zA-Z0-9 ,]*")],
      ],
      countryCity: ["", [Validators.required]],
      next_destination: [" ", [Validators.pattern("[a-zA-Z0-9 ,]*")]],
      passportNumber: [
        "",
        [Validators.required, Validators.pattern("[a-zA-Z0-9]*")],
      ],
      issueDate: ["", [Validators.required]],
      expiredDate: ["", [Validators.required]],
      countryOfPassport: ["", [Validators.required]],
      visaType: ["1"],
      portOfEntry: [""],
      intendedDate: ["", [Validators.required]],
      vehicle: ["", [Validators.pattern("[a-zA-Z0-9 ,]*")]],
      purposeOfTrip: [
        "",
        [Validators.required, Validators.pattern("[a-zA-Z0-9 ,]*")],
      ],
      addressDuringVisit: [
        "",
        [Validators.required, Validators.pattern("[a-zA-Z0-9 ,]*")],
      ],
      myfileFlight: ["", []],
      myfileHotel: ["", []],
      ThirdCountryVisa: ["", []],
      guarantor: ["", []],
      bank_statement: ["", []],
      race: [0, []],

      family_side: ["", []],
      family_member_names: ["", []],
      family_relation: ["", []],
    });

    this.person = this.formDataService.getFormData();
    this.custImage = this.formDataService.getFormData();
    this.person.currentActiveForm = "1";
    console.log(this.person);
    $(document).ready(function () {
      $("#warningUploadPassport").hide();
      $("#warningUploadPhoto").hide();
      $("#btnContinue").click(function () {
        if ($("#inputPassportFile")[0].files.length === 0) {
          $("#warningUploadPassport").show();
        }
        if ($("#inputPhotoFile")[0].files.length === 0) {
          $("#warningUploadPhoto").show();
        }
      });
      $("#btnshowModalPassport").click(function () {
        $("#warningUploadPassport").hide();
      });
      $("#btnshowModalPhoto").click(function () {
        $("#warningUploadPhoto").hide();
      });
    });
  }

  private scrollToFirstInvalidControl() {
    const firstInvalidControl: HTMLElement =
      this.el.nativeElement.querySelector("form .ng-invalid");

    // firstInvalidControl.focus(); //without smooth behavior
    window.scroll({
      top: this.getTopOffset(firstInvalidControl),
      left: 0,
      behavior: "smooth",
    });
  }

  getTopOffset(controlEl: HTMLElement): number {
    const labelOffset = 150;
    return controlEl.getBoundingClientRect().top + window.scrollY - labelOffset;
  }

  onActivate(event) {
    this.window.scroll(0, 0);
    // or document.body.scrollTop = 0;
    // or document.querySelector('body').scrollTo(0,0)
  }
}
